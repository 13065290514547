import { APP_CONFIG_INFO, APP_CONFIG_RESET, IAppConfig } from 'src/store/app-config/action';
import { LOCALSTORAGE_APP_CONFIG } from 'src/constants/const';
import { getObjectLocalStorage, saveObjectLocalStorage } from 'src/services/storage';

const initialState: IAppConfig = {
  langCd: 'en',
  countryCd: '',
  ...getObjectLocalStorage(LOCALSTORAGE_APP_CONFIG),
};

export const appConfig = (state: IAppConfig = initialState, action: any) => {
  switch (action.type) {
    case APP_CONFIG_INFO:
      let dataUpdate = null;
      if (!!action.payload) {
        dataUpdate = {
          ...state,
          ...(action.payload.langCd && { langCd: action.payload.langCd }),
          ...(action.payload.countryCd && { countryCd: action.payload.countryCd }),
        };
      }
      saveObjectLocalStorage(LOCALSTORAGE_APP_CONFIG, dataUpdate);
      return dataUpdate;
    case APP_CONFIG_RESET:
      return initialState;
    default:
      return state;
  }
};
