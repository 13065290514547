export const CT_ENV_SITE = {
  develop: 'development',
  production: 'production',
};

export const CT_ENV_FLAVOR = process.env.REACT_APP_FLAVOR ?? '';

export const WEB_DOMAIN_PRODUCT = 'www.global.golfzon.com';

export const CT_BASE_URL = process.env.REACT_APP_API_URL ?? '';
export const CT_GLOBAL_DOMAIN = process.env.REACT_APP_GLOBAL_DOMAIN ?? '';
