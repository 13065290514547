import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { connect } from 'react-redux';
import { isIOS, isMobile } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';
import DropdownMenu from 'src/view/commons/elements/Dropdown';
import { locales, LOCALSTORAGE_APP_CONFIG, pathConstants } from 'src/constants/const';
import { IconAngleLeft, iconGlobe } from 'src/assets/svg';
import { appConfigAction, IAppConfig } from 'src/store/app-config';
import { getObjectLocalStorage } from 'src/services/storage';

interface IHeaderProps {
  langCd?: string;
  configInfoApp: (value: IAppConfig) => void;
}

function Header({ langCd, configInfoApp }: IHeaderProps): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentLang, setCurrentLang] = useState<string>();

  const isLoginPath = location.pathname === pathConstants.LOGIN;

  useEffect(() => {
    if (isLoginPath) {
      const appConfig = getObjectLocalStorage(LOCALSTORAGE_APP_CONFIG);
      if (!!appConfig?.langCd) {
        configInfoApp({ langCd: appConfig?.langCd });
        setCurrentLang(appConfig?.langCd);
      }
    }
  }, [location]);

  useEffect(() => {
    setCurrentLang(langCd);
  }, [langCd]);

  const changeLanguage = (language: string) => {
    setCurrentLang(language);
    configInfoApp({ langCd: language });
  };

  const onBackButtonEvent = (e: any) => {
    e.preventDefault();
  };

  useEffect(() => {
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, [location]);

  const back = () => {
    navigate(-1);
  };

  const headerElement = () => {
    return (
      <>
        {!isLoginPath ? (
          <div className="flex w-full items-center">
            <div
              className="ml-[10px] flex flex-shrink-0 cursor-pointer
            rounded-[50%] p-2 active:bg-light-gray"
              onClick={back}
            >
              <IconAngleLeft className="h-4 w-4 stroke-title" />
            </div>
          </div>
        ) : (
          <>
            <div />
            <div className="gz-text-xs flex items-center px-3 py-2.5 md:!px-16">
              <img src={iconGlobe} alt="union" className="h-4 w-4" aria-hidden="true" />
              <DropdownMenu
                value={currentLang}
                items={locales}
                classItem={isMobile ? 'hover:bg-gz-white w-[104px]' : 'w-[128px]'}
                onChange={changeLanguage}
              />
            </div>
          </>
        )}
      </>
    );
  };

  if (isMobile && isIOS) {
    return (
      <header id="ios-toolbar-wrap" className={twMerge('sticky top-0 z-10 h-[44px] w-full')}>
        <div
          id="ios-toolbar"
          className={twMerge('absolute left-0 right-0 bg-gz-white', 'flex items-center justify-between')}
        >
          {/* {headerElement()} */}
        </div>
      </header>
    );
  }

  return <header className={twMerge('flex h-[44px] items-center justify-between')}>{/* {headerElement()} */}</header>;
}

const mapStateToProps = (state: any) => ({
  langCd: state.appConfig.langCd,
});
const mapDispatchToProps = {
  configInfoApp: appConfigAction.configInfoApp,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
