import { configureStore, combineReducers } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { authentication, LOGOUT_SUCCESS } from 'src/store/account/login';
import { appConfig } from 'src/store/app-config';
import { notification } from 'src/store/notification';

const allReducers = combineReducers({
  authentication,
  appConfig,
  notification,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === LOGOUT_SUCCESS) {
    state = undefined;
  }
  return allReducers(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
