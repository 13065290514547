const EMAIL = /^\w+([\.-]?\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

// For only allow to be enter <Input />
const NUMBER_DOT_ONLY = /[^\d.]/;

// For only number
const NUMBER_ONLY = /^\d+$/;

// Nickname must have number, special, lowercase, uppercase or japanese
// const NICKNAME = /^[\sA-Za-z0-9ぁ-んァ-ン一-龥(!@#$^&+=)]{4,12}$/;
const NICKNAME = /^[ぁ-んァ-ンｧ-ﾝﾞﾟ一-龥A-Za-z0-9Ａ-Ｚ０-９々〆〤ヶぁ-ゔァ-ヴー(!@#$^&+=)]{4,12}$/;

//check avatar file type
const AVATAR_FILE_TYPE = /jpg|jpeg|gif/;

export const RegExConstants = {
  EMAIL,
  NUMBER_DOT_ONLY,
  NUMBER_ONLY,
  NICKNAME,
  AVATAR_FILE_TYPE,
};
