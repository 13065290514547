import Cookies from 'js-cookie';

export const saveStorageInfo = (key: string, value: string) => {
  Cookies.set(key, value, { expires: 365 });
};

export const getStorageInfo = (key: string) => {
  return Cookies.get(key);
};

export const removeStorageInfo = (key: string) => {
  Cookies.remove(key);
};

export const removeAllCookies = () => {
  Object.keys(Cookies.get()).forEach(function (cookieName) {
    const neededAttributes = {};
    Cookies.remove(cookieName, neededAttributes);
  });
};
