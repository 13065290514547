export interface IAppConfig {
  langCd?: string;
  countryCd?: string;
}

export const APP_CONFIG_INFO = 'APP_CONFIG_INFO';
export const APP_CONFIG_RESET = 'APP_CONFIG_RESET';

const configInfoApp = (dataLangCountry: IAppConfig) => ({
  type: APP_CONFIG_INFO,
  payload: dataLangCountry,
});
const resetConfig = () => ({
  type: APP_CONFIG_RESET,
});

export const appConfigAction = {
  configInfoApp,
  resetConfig,
};
