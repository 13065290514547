import { STORAGE_KEY } from './../../../constants/const/storage.constants';
import { AnyAction } from 'redux';
import { getStorageInfo } from 'src/requests/token';
import { LOGIN_FAILED, LOGIN_START, LOGIN_SUCCESS, LOGIN_WITH_SESSION_ID, LOGOUT_SUCCESS } from './action';
import { IAuthentication } from 'src/requests/api/account/prop-state.type';

const initialState: IAuthentication = {
  sessionId: getStorageInfo(STORAGE_KEY.channelKey),
};

export const authentication = (state: IAuthentication = initialState, action: AnyAction) => {
  switch (action.type) {
    case LOGIN_START:
      return {
        ...state,
        isLoggingIn: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggingIn: false,
        sessionId: action.payload.sessionData.sessionId,
      };
    case LOGIN_WITH_SESSION_ID:
      return {
        sessionId: action.payload.sessionId,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        isLoggingIn: false,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        sessionId: null,
      };
    default:
      return state;
  }
};
