import { notitficationActionTypes } from 'src/constants/const/index';
import { EnumNotificationPositionType } from 'src/constants/enum';
import { NotificationType, INotiAdditional } from 'src/models';

const addNotification = (
  message: string,
  type: NotificationType = NotificationType.INFO,
  additional?: INotiAdditional,
  position?: EnumNotificationPositionType,
) => ({
  type: notitficationActionTypes.ADD_NOTIFICATION,
  payload: {
    message,
    type,
    additional,
    position,
  },
});

const removeNotification = () => ({
  type: notitficationActionTypes.REMOVE_NOTIFICATION,
});

export const notificationActions = {
  addNotification,
  removeNotification,
};
