import axios from 'src/requests';

const loginWithIdAndPassword = async (username: string, password: string, externalUserId: string): Promise<any> => {
  const body = {
    usrId: username,
    password,
    externalUserId,
  };
  const response = await axios.post('/external/login/auth', body);
  return response.data.data;
};

export const loginMiddleware = {
  loginWithIdAndPassword,
};
