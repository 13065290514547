import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import i18n from 'src/locale';
import { RootState } from 'src/store';
import { notificationActions } from 'src/store/notification/action';
import { NotificationType } from 'src/models';
import { loginMiddleware } from 'src/requests/api/account/login';
import { ISignInFormResponse } from 'src/requests/api/account/prop-state.type';

export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_WITH_SESSION_ID = 'LOGIN_WITH_SESSION_ID';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';

export const login = {
  start: () => ({
    type: LOGIN_START,
  }),
  success: (sessionData: ISignInFormResponse) => ({
    type: LOGIN_SUCCESS,
    payload: {
      sessionData,
    },
  }),
  failed: () => ({
    type: LOGIN_FAILED,
  }),
};

const loginWithIdAndPassword =
  (
    username: string,
    password: string,
    externalUserId: string,
  ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>): Promise<void> => {
    try {
      dispatch(login.start());
      const response = await loginMiddleware.loginWithIdAndPassword(username, password, externalUserId);
      if (response.entities?.memberResult === 100) {
        dispatch(handleAfterLogin(response.entities));
      } else {
        dispatch(
          notificationActions.addNotification(i18n.t('error.INVALID_USERNAME_OR_PASSWORD'), NotificationType.DANGER),
        );
        dispatch(login.failed());
      }
    } catch (error: any) {
      console.warn(error);
      dispatch(notificationActions.addNotification(i18n.t('error.UNKNOWN_ERROR'), NotificationType.DANGER));
      dispatch(login.failed());
    }
  };

const handleAfterLogin = (response: any) => async (dispatch: any) => {
  try {
    dispatch(login.success(response));
    let callBackURL = response?.urlRedirect || '';
    if (!callBackURL) return;
    if (!callBackURL.match(/^https?:\/\//i)) {
      callBackURL = 'http://' + callBackURL;
    }
    window.open(callBackURL, '_blank');
  } catch (error: any) {
    console.warn(error);
    dispatch(notificationActions.addNotification(i18n.t('error.UNKNOWN_ERROR'), NotificationType.DANGER));
  } finally {
    // removeAllCookies();
  }
};

export const authActions = {
  loginWithIdAndPassword,
  handleAfterLogin,
};
