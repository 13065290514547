import { useTranslation } from 'react-i18next';

function Footer(): JSX.Element {
  const translate = useTranslation().t;
  return (
    <div className="flex justify-center ">
      <div className="gz-text-tiny !leading-[20px] text-disable">
        <div className="mt-[6px] text-center">{translate('layouts.footer.COPYRIGHT')}</div>
      </div>
    </div>
  );
}

export default Footer;
