export enum EnumStatusCode {
  SUCCESS = 100,
  FAILED = 101,
  ERROR = 102,
}

export enum EnumRequestCode {
  SUCCESS = '1',
  FAILED = '0',
}

export enum EnumVerifyTokenCode {
  SUCCESS = '200',
  FAILED = '401',
}

export enum EnumValueBoolean {
  TRUE = 'Y',
  FALSE = 'N',
}

export enum EnumNotificationPositionType {
  TOP,
  BOTTOM,
}
