/* eslint-disable no-console */
import { lazy, Suspense, useEffect } from 'react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NotificationContainer from 'src/view/commons/customs/alert-mess/NotificationContainer';

import { CT_ENV_SITE, pathConstants } from 'src/constants/const';
import BasicLayout from 'src/view/layouts/guest/MainLayout';
// Start Code spliting
const LoginPage = lazy(() => import('src/view/pages/account/login'));
const NotFound = lazy(() => import('src/view/pages/not-found'));

// End Code spliting

interface IAppProps {
  langCd?: string;
}

function App({ langCd }: IAppProps): JSX.Element {
  useEffect(() => {
    if (process.env.NODE_ENV !== CT_ENV_SITE.develop) {
      console.log = () => {};
      console.debug = () => {};
      console.info = () => {};
      console.warn = () => {};
    }
  }, []);

  return (
    <>
      <NotificationContainer />
      <Routes>
        <Route
          element={
            <BasicLayout>
              <Suspense fallback={<></>}>
                <Outlet />
              </Suspense>
            </BasicLayout>
          }
        >
          <Route path={pathConstants.LOGIN} element={<LoginPage />} />
        </Route>

        {/* Unknown routes. Redirect to 404 page when users access to the unknown routes */}
        <Route path={pathConstants.PAGE_NOT_FOUND} element={<NotFound />} />
        <Route path="*" element={<Navigate to={pathConstants.PAGE_NOT_FOUND} replace />} />
      </Routes>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  langCd: state.appConfig.langCd,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(App);
