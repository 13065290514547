import i18next from 'i18next';
import en from './en_EN.json';
import vn from './vn_VN.json';
import cn from './cn_CN.json';
import ja from './jp_JP.json';
import kr from './kr_KR.json';

const lang = 'en';

const resources = {
  en: {
    translation: en,
  },
  vn: {
    translation: vn,
  },
  cn: {
    translation: cn,
  },
  ja: {
    translation: ja,
  },
  kr: {
    translation: kr,
  },
};

i18next.init({
  interpolation: { escapeValue: false },
  lng: lang,
  fallbackLng: lang,
  resources: resources,
});

export default i18next;
