export const globalPath = {
  FIND_ID: '/find-id',
  FORGOT_PW: '/find-password',
  SIGN_UP: '/signup',
  TERM_SERVICES: '/terms-of-use',
  PRIVACY_POLICY: '/privacy-policy',
};

export const languageCode = {
  KOREA: 'ko',
  USA: 'en',
  CHINA: 'zh',
  JAPAN: 'ja',
  VIETNAM: 'vi',
};
export const countryCode = {
  KOREA: 'kr',
  USA: 'en',
  CHINA: 'cn',
  JAPAN: 'ja',
  VIETNAM: 'vn',
};

export const locales = [
  { title: '한국어', value: countryCode.KOREA },
  { title: 'English', value: countryCode.USA },
  { title: '中文(简体)', value: countryCode.CHINA },
  { title: '日本語', value: countryCode.JAPAN },
  { title: 'Tiếng Việt', value: countryCode.VIETNAM },
];

export const notitficationActionTypes = {
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  REMOVE_NOTIFICATION: 'REMOVE_NOTIFICATION',
  CLEAR_ALL_NOTIFICATION: 'CLEAR_ALL_NOTIFICATION',
};
