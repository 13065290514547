import Footer from 'src/view/layouts/guest/Footer';
import Header from './Header';

interface Props {
  children?: JSX.Element;
}

function BasicLayout({ children }: Props): JSX.Element {
  return (
    <>
      <Header />
      <main className="mx-auto flex max-w-[600px] flex-col content-center items-center bg-gz-white px-4 md:!px-8">
        {children}
        <Footer />
      </main>
    </>
  );
}

export default BasicLayout;
